(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/dialog-wrapper/assets/javascripts/dialog-wrapper-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/dialog-wrapper/assets/javascripts/dialog-wrapper-actions.js');

'use strict';

const SHOW_DIALOG = 'DIALOG/SHOW_DIALOG';
const showDialog = _ref => {
  let {
    title,
    text,
    buttons,
    type,
    icon
  } = _ref;
  return {
    type: SHOW_DIALOG,
    title,
    text,
    buttons,
    dialogType: type,
    icon
  };
};
const CLOSE_DIALOG = 'DIALOG/CLOSE_DIALOG';
const closeDialog = () => ({
  type: CLOSE_DIALOG
});
setGlobal('svs.components.marketplaceData.dialogWrapper.actions', {
  SHOW_DIALOG,
  showDialog,
  CLOSE_DIALOG,
  closeDialog
});

 })(window);